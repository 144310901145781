import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/map",
  },
  {
    path: "/bim",
    name: "bim",
    component: () => import(/* webpackChunkName: "bim" */ "../views/BIMView.vue"),
  },
  {
    path: "/map",
    name: "map",
    component: () => import(/* webpackChunkName: "bim" */ "../components/itowns/Map3DContainer.vue"),
  },
  {
    path: "/main",
    name: "main",
    // route level code-splitting
    // this generates a separate chunk (main.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "main" */ "../views/MainView.vue"),
    children: [
      {
        path: "emission",
        name: "emission",
        component: () => import(/* webpackChunkName: "blank" */ "../views/BIMView.vue"),
      },
      {
        path: "consumption",
        name: "consumption",
        component: () => import(/* webpackChunkName: "consumption" */ "../views/ConsumptionView.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
