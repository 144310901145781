import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import axios from "axios";
import "element-plus/dist/index.css";
import "@/assets/mapbox-gl.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import * as ECharts from "echarts";
import theme from "@/assets/tech.json";
ECharts.registerTheme("tech", theme);

const app = createApp(App);
app.use(store).use(router).use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.provide("$axios", axios);
app.mount("#app");
